import styled from "@emotion/styled";

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  & > div {
    width: auto;
    max-width: 600px;
  }

  @media screen and (min-width: 768px) and (max-width: 1440px) {
    justify-content: unset;
    & > div {
      width: 50%;
    }
  }
`;

export const CallToAction = styled.div`
  padding: 4rem 4.5rem;
`;

export const Callout = styled.div`
  padding: 3rem 0;
  text-align: center;
`;

export const Placeholder = styled.div`
  height: 65vh;
  background: white;
  margin: 0 10rem 5rem;
`;

export const Pane = styled.section`
  margin: 3.75rem;
`;
