import type { NextPage } from "next";
import Head from "next/head";
import Navigation from "./navigation";
import Image from "next/image";
import Button from "src/components/component-library/button";
import { HeroContainer, CallToAction, Callout, Placeholder } from "./style";
import sampleResume from "public/images/sample-resume.png";
import Link from "next/link";

const Home: NextPage = () => {
  return (
    <div>
      <Head>
        <title>Pioneer</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Navigation />

      <main>
        <HeroContainer>
          <CallToAction>
            <h1>Create a resume as unique as you</h1>
            <p>
              Pioneer is the first design-driven resume tool with built-in
              sharing and tracking.
            </p>
            <Link href="/auth/signin">
              <a>
                <Button wide>Get Started</Button>
              </a>
            </Link>
          </CallToAction>
          <div>
            <Image
              src={sampleResume}
              alt="Sample resume"
              // width={500} automatically provided
              // height={500} automatically provided
              // blurDataURL="data:..." automatically provided
              // placeholder="blur" // Optional blur-up while loading
            />
          </div>
        </HeroContainer>

        <Callout>
          <h2>
            Better, Smarter, and More Beautiful Resumes. <br />
            Now, Easier than Ever.
          </h2>
        </Callout>

        <Placeholder />
      </main>
    </div>
  );
};

export default Home;
