import Link from "next/link";
import Button from "src/components/component-library/button";
import { Nav, NavSection } from "./style";
import { RiMenuLine as MenuIcon } from "react-icons/ri";

const Navigation = () => (
  <Nav>
    <NavSection className="left">
      <li>
        <Link href="/">
          <a>
            <h3>Pioneer</h3>
          </a>
        </Link>
      </li>
    </NavSection>
    <NavSection className="middle">
      <li>
        <Link href="/templates">
          <a>Templates</a>
        </Link>
      </li>
      <li>
        <Link href="/pricing">
          <a>Pricing</a>
        </Link>
      </li>
      <li>
        <Link href="/learn">
          <a>Learn</a>
        </Link>
      </li>
    </NavSection>
    <NavSection className="right">
      <li className="mobileMenu">
        <MenuIcon />
      </li>
      <li>
        <Link href="/auth/signin">
          <a>Sign In</a>
        </Link>
      </li>
      <li>
        <Link href="/auth/signin">
          <a>
            <Button>Get Started</Button>
          </a>
        </Link>
      </li>
    </NavSection>
  </Nav>
);

export default Navigation;
