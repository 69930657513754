import styled from "@emotion/styled";

export const Nav = styled.nav`
  display: flex;
  width: 100%;
  height: 80px;
  flex-direction: row;
  align-items: baseline;

  & * {
    font-family: "Darker Grotesque";
    font-weight: 600;
  }
`;

export const NavSection = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: auto 0;
  padding: 0 40px;
  width: 33.3%;

  a {
    text-decoration: none;
  }

  &.left {
    flex-grow: 1;
    justify-content: flex-start;
  }
  &.right {
    flex-grow: 1;
    justify-content: flex-end;
    padding-left: 0;

    li {
      margin: auto 1rem;
    }

    & > .mobileMenu {
      display: none;
    }

    @media screen and (max-width: 768px) {
      &.right > * {
        display: none;
      }

      & > .mobileMenu {
        display: flex;
        cursor: pointer;
      }
    }
  }
  &.middle {
    flex-grow: 2;
    justify-content: center;

    li {
      margin: auto 1rem;
    }

    @media screen and (max-width: 768px) {
      &.middle {
        display: none;
      }
    }
  }

  li {
    font-size: 1rem;
  }
`;
