import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

type ButtonType = "primary" | "secondary" | "tertiary";

export const Button = styled.button<{
  buttonType?: ButtonType;
  wide?: boolean;
}>`
  background: ${({ theme }) => theme.COLORS.button.primary};
  font-family: ${({ theme }) => theme.TYPOGRAPHY.FONTS.button};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.FONT_SIZES.button};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.FONT_WEIGHTS.medium};
  color: ${({ theme }) => theme.COLORS.text.primary};
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: ${({ wide }) => (wide ? "40px" : "20px")};
  padding-right: ${({ wide }) => (wide ? "40px" : "20px")};
  border-radius: 7px;
  cursor: pointer;
  border: none;

  &:hover {
    background: ${({ theme }) => theme.COLORS.button.primaryHover};
  }

  &:not(:first-of-type):not(:last-of-type) {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  ${({ theme, buttonType }) => buttonTypeStyles(buttonType, theme)}
`;

const buttonTypeStyles = (buttonType: ButtonType | undefined, theme: Theme) => {
  const styles = {
    primary: ``,
    secondary: secondaryStyles(theme),
    tertiary: tertiaryStyles(theme),
  };

  if (!buttonType) {
    return () => ``;
  }

  return styles[buttonType];
};

const secondaryStyles = (theme: Theme) => `
  background: ${theme.COLORS.button.secondary};

  &:hover {
    background: ${theme.COLORS.button.secondaryHover};
  }
`;

const tertiaryStyles = (theme: Theme) => `
  background: ${theme.COLORS.button.tertiary};

  &:hover {
    background: ${theme.COLORS.button.tertiaryHover};
  }
`;
